<template>
  <div>
    <Pane :showRemarks="false" :bottom="0">
      <a-tabs v-model="active" :tabBarStyle="{
        margin: '0',
      }">
        <a-tab-pane key="info" tab="基本信息"> </a-tab-pane>
        <a-tab-pane key="subpackage" tab="分包"> </a-tab-pane>
      </a-tabs>
    </Pane>

    <div class="container">
      <div class="content" v-show="active === 'info'">
        <a-form :form="form" :label-col="{ span: 8 }" :wrapper-col="{ span: 12 }" :colon="false" @submit="handleSubmit">
          <a-row>
            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="设计编号">
                <a-input v-decorator="[
                  'code',
                  { rules: [{ required: true, message: '请输入！' }] },
                ]" />
              </a-form-item>
            </a-col>
            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="合同名称">
                <a-input :disabled="isProduction" v-decorator="[
                  'name',
                  { rules: [{ required: true, message: '请输入！' }] },
                ]" />
              </a-form-item>
            </a-col>
            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="合同金额(万元)">
                <a-input-number :formatter="$inputNumberFormat" :disabled="!isContractAdmin" v-decorator="[
                  'contractAmount',
                  { rules: [{ required: true, message: '请输入！' }] },
                ]" style="width: 100%" />
              </a-form-item>
            </a-col>

            <a-col :lg="12" :md="12" :sm="24" v-if="!checkBranch(form.getFieldValue('productionUnitId'))">
              <a-form-item label="所属区域">
                <a-cascader placeholder="" changeOnSelect v-decorator="[
                  'area',
                  {
                    initialValue: areaIdList,
                  },
                ]" :options="areaList" :fieldNames="{
  label: 'areaName',
  value: 'id',
  children: 'children',
}" />
              </a-form-item>
            </a-col>

            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="生产部门">
                <a-select show-search :filter-option="$selectFilterOption" v-decorator="[
                  'oriProductionUnitId',
                  { rules: [{ required: true, message: '请选择！' }] },
                ]" style="width: 100%" placeholder="">
                  <a-select-option v-for="item in productionDepartmentList" :key="item.id" :value="item.id">{{
                    item.uniqueName }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="责任部门">
                <a-select show-search :filter-option="$selectFilterOption" v-decorator="[
                  'productionUnitId',
                  { rules: [{ required: true, message: '请选择！' }] },
                ]" style="width: 100%" placeholder="">
                  <a-select-option v-for="item in productionDepartmentList" :key="item.id" :value="item.id">{{
                    item.uniqueName }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="生产负责人">
                <div class="">
                  <!-- 生产负责人新增、编辑合同时，生产负责人字段不能改，就是自身 -->
                  <EmployeeSelector :disabled="isProduction" @change="(arr) => {
                    if (arr.length) {
                      charger = arr[0];
                      // 校验用户选择的人是否符合需求（不属于院长和合同管理员角色）
                      checkUserRole(arr[0].userId).catch((message) => {
                        $message.error(message);
                        charger = {};
                      });
                    } else {
                      charger = {};
                    }
                  }
                    " :value="charger.name ? [charger] : []">
                    <a-button block style="text-align: left" :disabled="isProduction">
                      <div v-if="charger.name" style="text-align: left">
                        {{ charger.name }}
                      </div>
                      <div v-else style="text-align: left">&nbsp;</div>
                    </a-button>
                  </EmployeeSelector>
                </div>
              </a-form-item>
            </a-col>

            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="合同类型">
                <a-select v-decorator="[
                  'type',
                  { rules: [{ required: true, message: '请选择！' }] },
                ]">
                  <a-select-option v-for="item in typeList" :key="item.value" :value="item.value"
                    :disabled="item.value === 'compensated' && !isContractAdmin">
                    {{ item.name }}
                  </a-select-option>
                </a-select>

                <!-- <a-radio-group
                  v-decorator="[
                    'type',
                    { rules: [{ required: true, message: '请选择！' }] },
                  ]"
                >
                  <a-radio
                    v-for="item in typeFilterList"
                    :key="item.id"
                    :value="item.value"
                    >{{ item.name }}</a-radio
                  >
                </a-radio-group> -->
              </a-form-item>
            </a-col>

            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="合同状态">
                <a-radio-group v-decorator="[
                  'contractSignStatus',
                  { rules: [{ required: true, message: '请选择！' }] },
                ]">
                  <a-radio v-for="item in signStatusList" :disabled="statusDisabled(item)" :key="item.id"
                    :value="item.value">{{ item.name }}</a-radio>
                </a-radio-group>
              </a-form-item>
            </a-col>

            <a-col :lg="24" :md="24" :sm="24">
              <a-form-item label="阶段" :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
                <a-checkbox-group v-decorator="[
                  'phase',
                  { rules: [{ required: true, message: '请选择！' }] },
                ]">
                  <div v-if="signStatus === 'unsigned'">
                    <a-checkbox v-for="item in showPhaseList" :key="item.id" :value="item.value">{{ item.name
                    }}</a-checkbox>
                  </div>

                  <a-row :gutter="[8, 8]" v-else>
                    <a-col :span="4" v-for="item in phaseList" :key="item.id">
                      <a-tooltip>
                        <template slot="title">
                          {{ item.name }}
                        </template>
                        <div class="text-hidden">
                          <a-checkbox :value="item.value">{{
                            item.name
                          }}</a-checkbox>
                        </div>
                      </a-tooltip>
                    </a-col>
                  </a-row>
                </a-checkbox-group>
              </a-form-item>
            </a-col>

            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="关联合同">
                <ContractSelector @change="onSelectContract" @cancel="cancelSelectContract">
                  <a-button style="text-align: left" class="text-hidden" block>
                    <span v-if="selectedContract.name">{{
                      selectedContract.name
                    }}</span>
                    <span v-else>&nbsp;</span>
                  </a-button>
                </ContractSelector>
              </a-form-item>
            </a-col>

            <!-- <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="业主方">
                <a-input v-decorator="['partyAname']" />
              </a-form-item>
            </a-col> -->

            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="业主方">
                <CompanySelector @change="(company) => (selectedCompany = company)">
                  <a-button block style="margin-top: 4px; text-align: left" class="ellipsis">
                    {{ selectedCompany.name }}
                  </a-button>
                </CompanySelector>
              </a-form-item>
            </a-col>


            <a-col :lg="24" :md="24" :sm="24">
              <a-form-item :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
                <span slot="label" class="">附件</span>
                <div class="link-list">
                  <a-checkbox-group :value="selectedFileList" @change="onSelectFile">
                    <div class="link" v-for="item in fileList" :key="item">
                      <a-checkbox :value="item" :disabled="item.indexOf('http') === -1">
                        <a target="_blank" download :href="item" v-if="item.indexOf('http') !== -1">{{
                          uploadFileNameFormatter(item) }}</a>
                        <a v-else @click.prevent="clickLink(item)">{{
                          fileNameFormatter(item)
                        }}</a>
                      </a-checkbox>
                    </div>
                  </a-checkbox-group>

                  <div class="control-bar">
                    <FileUpload @uploaded="uploaded">
                      <div class="control">
                        <a-icon type="upload" />
                      </div>
                    </FileUpload>

                    <div class="control" @click="deleteFile">
                      <a-icon type="delete" />
                    </div>
                  </div>
                </div>
              </a-form-item>
            </a-col>

            <a-col :lg="24" :md="24" :sm="24">
              <a-form-item label="工程概况" :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
                <a-textarea :auto-size="{ minRows: 3 }" v-decorator="['scale']" />
              </a-form-item>
            </a-col>

            <a-col :lg="24" :md="24" :sm="24">
              <a-form-item label="合同收款项" :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
                <a-table bordered :data-source="planReceiptList" :pagination="false">
                  <a-table-column title="收款进度编号" data-index="code" />
                  <a-table-column title="收款进度名称" data-index="name" />

                  <a-table-column title="收款比例" align="right">
                    <template slot-scope="text">
                      <div>
                        <span>{{ text.ratio }}%</span>
                      </div>
                    </template>
                  </a-table-column>

                  <!-- <a-table-column title="负责人" data-index="chargerName" /> -->

                  <a-table-column title="收款金额(万元)" align="right">
                    <template slot-scope="text">
                      <Money :money="text.planReceipt" />
                    </template>
                  </a-table-column>

                  <!-- <a-table-column
                    title="收款项金额（元）"
                    data-index="planReceipt"
                    align="right"
                  >
                    <template slot-scope="planReceipt">
                      {{ moneyFilter(planReceipt) }}
                    </template>
                  </a-table-column> -->

                  <a-table-column title="进度确认" align="center">
                    <template slot-scope="text">
                      <span v-if="text.isFinished">
                        <a-icon type="check" style="color: #1890ff; font-size: 18px" />
                      </span>
                    </template>
                  </a-table-column>

                  <!-- <a-table-column
                    title="计划年月"
                    data-index="planReceiptYearmonth"
                    align="center"
                  >
                    <template slot-scope="planReceiptYearmonth">
                      {{ dateFormatter(planReceiptYearmonth) }}
                    </template>
                  </a-table-column> -->
                </a-table>
              </a-form-item>
            </a-col>

            <a-col :lg="24" :md="24" :sm="24">
              <a-form-item label="合同规模" :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
                <ScaleList :list="contractScaleList" />
              </a-form-item>
            </a-col>
          </a-row>
          <div class="center">
            <a-space>
              <a-button type="primary" html-type="submit">下一步</a-button>
              <a-button @click="$close($route.path)">关闭</a-button>
            </a-space>
          </div>
        </a-form>

        <a-form :label-col="{ span: 8 }" :wrapper-col="{ span: 12 }" :colon="false">
          <a-form-item :label-col="{ span: 4 }" :wrapper-col="{ span: 18, offset: 4 }">
            <ExtraInfo />
          </a-form-item>
        </a-form>
      </div>

      <div class="content" v-show="active === 'subpackage'">
        <SubPackage :necessaryList="necessaryList" :list="list" :otherList="otherList" @setList="setList" />

        <div class="center">
          <a-space>
            <a-tooltip>
              <template slot="title">
                <span v-if="disabled1">审核中，无法修改</span>
                <span v-else-if="disabled2">已审核成功，无法修改</span>
                <span v-else>保存后会发起审批</span>
              </template>
              <a-button :loading="saveLoading" type="primary" @click="save"
                :disabled="disabled1 || disabled2">保存</a-button>
            </a-tooltip>
            <a-button @click="$close($route.path)">关闭</a-button>
          </a-space>
        </div>
      </div>
    </div>

    <CommentModal :visible="commentVisible" :title="commentTitle" @setVisible="setCommentVisible" @done="done" />
  </div>
</template>


<script>
import area from "@/mixins/area";
import organization from "@/mixins/organization";
import watermark from "@/mixins/watermark";
import moment from "moment";
import { fetchDetail, edit,  } from "@/api/contract";
import { checkUserRole } from "./utils";
import { mapGetters, mapState } from "vuex";

import FileUpload from "@/components/file-upload";
import { encode } from "js-base64";
import SubPackage from "./components/subpackage";
import CommentModal from "@/components/comment-modal";
import ExtraInfo from "./components/extra-info";
import EmployeeSelector from "@/components/employee-selector";
import ScaleList from "./components/scale-list";
import ContractSelector from "@/components/contract-selector";

import CompanySelector from "@/components/company-selector";

export default {
  name: "editContract",
  mixins: [area, organization, watermark],

  components: {
    FileUpload,
    SubPackage,
    CommentModal,
    ExtraInfo,
    EmployeeSelector,
    ScaleList,
    ContractSelector,
    CompanySelector,
  },

  data() {
    return {
      active: "info",
      form: this.$form.createForm(this),
      detail: {}, // 从接口获取到的合同详情
      id: null,
      signStatus: null,
      fileList: [],
      selectedFileList: [],

      planReceiptList: [], // 合同收款项

      writtenValue: {}, // 暂存用户填写的表单

      necessaryList: [], // 必要分包
      list: [], // 非必要
      otherList: [],

      charger: {}, // 生产负责人

      areaIdList: [], // 查询详情得来的所属区域

      saveLoading: false,

      commentVisible: false, // 审批意见弹窗
      commentTitle: "", // 标题
      storageValue: {}, // 准备提交的表单数据

      checkUserRole,

      contractScaleList: [], // 合同规模列表

      selectedContract: {},

      selectedCompany: {}, // 业主方
    };
  },

  computed: {
    ...mapGetters("auth", ["isProduction", "isPresident", "isContractAdmin"]), // 生产管理员角色、院长

    ...mapGetters("setting", ["findDataDict"]),

    typeList() {
      return this.findDataDict("contractType");
    },

    // typeFilterList() {
    //   return this.typeList.filter((item) => item.value !== "compensated");
    // },

    phaseList() {
      return this.findDataDict("phase");
    },
    signStatusList() {
      return this.findDataDict("contractSignStatus");
    },

    unionList() {
      return this.findDataDict("unionType");
    },

    // 提示语集合
    messageList() {
      return this.findDataDict("message");
    },

    showPhaseList() {
      return this.phaseList.filter((item) => item.value === "FA");
    },

    disabled1() {
      // 如果是在审核中，就禁用
      return this.detail.configureStatus === "reviewing";
    },

    ...mapState("auth", ["user"]),

    disabled2() {
      // 如果审核成功，并且是生产管理角色也禁用保存按钮
      return (
        this.detail.configureStatus === "approved" &&
        this.user &&
        Array.isArray(this.user.roles) &&
        this.user.roles.findIndex((item) => item.code === "product") > -1
      );
    },
  },

  activated() {
    const { query } = this.$route;
    const { id } = query || {};

    if (id && this.id !== id) {
      this.id = id;
      // 清空缓存
      this.detail = {}
      this.necessaryList = [];
      this.list = []
      this.otherList = [];
      this.getDetail(id);
    }
  },

  methods: {
    getDetail(id) {
      fetchDetail(id).then((res) => {
        this.detail = Object.freeze(res);

        let areaIdList = [];
        if (res.areaId) {
          areaIdList.push(res.areaId);
        }
        if (res.subAreaId) {
          areaIdList.push(res.subAreaId);
        }
        if (res.l3AreaId) {
          areaIdList.push(res.l3AreaId);
        }

        this.areaIdList = areaIdList;

        // 业主方
        this.selectedCompany.id = res.partyAid;
        this.selectedCompany.name = res.partyAname;

        this.form.setFieldsValue({
          code: res.code,
          name: res.name,
          contractAmount: Number((res.contractAmount / 10000).toFixed(6)),
          type: res.type,
          contractSignStatus: res.contractSignStatus,
          phase: res.phase.split(","),
          scale: res.scale,
          oriProductionUnitId: res.oriProductionUnitId,
          productionUnitId: res.productionUnitId,
          area: areaIdList,

        });

        // 生产负责人
        this.charger = {
          userId: res.productionMasterId,
          name: res.productionMasterName,
        };

        this.selectedContract = {
          id: res.relationContractId,
          name: res.relationContractName,
        };

        if (res.attachments) {
          this.fileList = res.attachments.split(",");
        }

        if (Array.isArray(res.planReceiptList)) {
          this.planReceiptList = res.planReceiptList;
        }

        if (Array.isArray(res.contractScaleList)) {
          this.contractScaleList = res.contractScaleList;
        }

        if (Array.isArray(res.projects) && res.projects.length > 0) {
          const item = res.projects[0];
          if (Array.isArray(item.subPackages)) {
            this.necessaryList = item.subPackages
              .filter((element) => element.isneeded === 1)
              .map((item) => {
                return {
                  ...item,
                  planAmount: item.planAmount / 10000,
                  fileList: item.attachments
                    ? item.attachments.split(",").map((item) => {
                      return {
                        name: this.$getFileName(item),
                        completePath: item,
                      };
                    })
                    : undefined,
                };
              });
            this.list = item.subPackages
              .filter((element) => element.isneeded === 0)
              .map((item) => {
                return {
                  ...item,
                  planAmount: item.planAmount / 10000,
                  fileList: item.attachments
                    ? item.attachments.split(",").map((item) => {
                      return {
                        name: this.$getFileName(item),
                        completePath: item,
                      };
                    })
                    : undefined,
                };
              });
            this.otherList = item.subPackages
              .filter((element) => element.isneeded === 2)
              .map((item) => {
                return {
                  ...item,
                  planAmount: item.planAmount / 10000,
                  fileList: item.attachments
                    ? item.attachments.split(",").map((item) => {
                      return {
                        name: this.$getFileName(item),
                        completePath: item,
                      };
                    })
                    : undefined,
                };
              });
          }
        }
      });
    },

    // 检查选择的是否是分公司，如果是分公司，不需要所属区域
    checkBranch(id) {
      if (id) {
        const obj = this.productionDepartmentList.find(
          (item) => item.id === id
        );
        if (obj) {
          return obj.type && obj.type === "branch";
        } else {
          return false;
        }
      } else {
        return false;
      }
    },

    uploaded(list) {
      this.fileList = [
        ...this.fileList,
        ...list.map((item) => item.completePath),
      ];
    },

    onSelectFile(values) {
      this.selectedFileList = values;
    },

    deleteFile() {
      if (this.selectedFileList.length === 0) {
        this.$message.error("请选择附件！");
        return;
      }
      this.selectedFileList.forEach((item) => {
        const index = this.fileList.findIndex((file) => file === item);
        if (index > -1) {
          this.fileList.splice(index, 1);
        }
      });
    },

    onSelectContract(obj) {
      console.log("obj", obj);
      this.selectedContract = obj;
    },
    cancelSelectContract() {
      this.selectedContract = {};
    },

    clickLink(path) {
      const num = path.split("_")[0];
      const time = moment().format("YYYY-MM-DD HH:mm:ss");
      const auth = encode(num + "_" + time);
      const url = `http://218.94.135.30:8025/FileStore/Download.aspx?auth=${auth}`;

      window.open(url)
      const that = this;
      this.$info({
        title: '如果浏览器无法下载，请手动复制下方文件链接',
        content: url,
        okText: '复制',
        onOk() {
          that.$copy(url)
        }
      })

      // const newWindow = window.open("_blank");
      // newWindow.location = url;
      // const hide = this.$message.loading("下载中...");
      // downloadFile({
      //   fileUrl: url,
      // })
      //   .then((blob) => {
      //     saveAs(blob, this.fileNameFormatter(path));
      //   })
      //   .finally(() => {
      //     hide();
      //   });

      // const that = this;
      // navigator.clipboard.writeText(url).then(
      //   function () {
      //     that.$success({
      //       title: "提示",
      //       content: "复制链接成功！",
      //     });
      //   },
      //   function () {
      //     that.$error({
      //       title: "提示",
      //       content: "复制链接失败！",
      //     });
      //   }
      // );

      // window.open(url);
    },

    handleSignStatusChange(e) {
      const { value } = e.target;
      this.signStatus = value;
      // 未签订时，选中方案
      if (value === "unsigned") {
        this.form.setFieldsValue({
          phase: ["FA"],
        });
      } else {
        this.form.setFieldsValue({
          phase: [],
        });
      }
    },

    // 金慧的文件名称格式化
    fileNameFormatter(path) {
      const arr = path.split("_");
      if (arr.length >= 2) {
        return arr[1];
      } else {
        return path;
      }
    },
    // 自己上传的文件名称格式化
    uploadFileNameFormatter(path) {
      const arr = path.split("_");
      if (arr.length > 0) {
        return arr[arr.length - 1];
      } else {
        return path;
      }
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err) => {
        if (!err) {
          this.active = "subpackage";
        }
      });
    },

    setList(payload) {
      console.log(payload)
      const { list, isNecessary, toggleNecessary, row } = payload;
      if (isNecessary !== toggleNecessary) {
        if (toggleNecessary === 1) {
          this.necessaryList.push(row);
        } else if (toggleNecessary === 0) {
          this.list.push(row);
        } else if (toggleNecessary === 2) {
          this.otherList.push(row);
        }
        return;
      }

      if (isNecessary === 1) {
        this.necessaryList = list;
      } else if (isNecessary === 0) {
        this.list = list;
      } else if (isNecessary === 2) {
        this.otherList = list;
      }
    },

    save() {
      this.form.validateFields((err, values) => {
        if (!err) {
          const that = this;

          // 如果当前登录用户是生产负责人角色，并且是选择的生产负责人，则提示他，保存会发起审批
          if (this.isProduction && this.user.uuid === this.charger.userId) {
            this.commentVisible = true;
            this.commentTitle = this.messageList.find(
              (item) => item.value === "editContract"
            ).name;
            this.storageValue = { values };
          } else {
            that.control({ values });
          }
        } else {
          this.$message.error("请填写合同信息！");
          this.active = "info";
        }
      });
    },

    // 审批意见弹窗设置
    setCommentVisible(visible) {
      this.commentVisible = visible;
    },

    // 用户填完审批意见
    done(comment) {
      this.control({
        ...this.storageValue,
        comment,
      });
    },

    control({ values, comment }) {
      const phaseStr = values.phase.join(",");

      let params = {
        id: this.id,

        ...values,
        contractAmount: values.contractAmount * 10000,

        productionMasterId: this.charger.userId, // 生产负责人

        partyAid: this.selectedCompany.id,
        partyAname: this.selectedCompany.name,

        phase: phaseStr,
        attachments:
          this.fileList.length > 0 ? this.fileList.join(",") : undefined,

        subPackages: [
          ...this.necessaryList.map((item) => {
            return {
              ...item,
              isneeded: 1,
              planAmount: item.planAmount * 10000,
              attachments: item.fileList
                ? item.fileList.map((el) => el.completePath).join()
                : undefined,
              fileList: undefined,
            };
          }),
          ...this.list.map((item) => {
            return {
              ...item,
              isneeded: 0,
              planAmount: item.planAmount * 10000,
              attachments: item.fileList
                ? item.fileList.map((el) => el.completePath).join()
                : undefined,
              fileList: undefined,
            };
          }),
          ...this.otherList.map((item) => {
            return {
              ...item,
              isneeded: 2,
              planAmount: item.planAmount * 10000,
              attachments: item.fileList
                ? item.fileList.map((el) => el.completePath).join()
                : undefined,
              fileList: undefined,
            };
          }),
        ],

        comment,

        relationContractId: this.selectedContract.id
          ? this.selectedContract.id
          : "",
        relationContractName: this.selectedContract.name
          ? this.selectedContract.name
          : "",
      };

      const { area } = values;

      let area1 = {};
      let area2 = {};
      let area3 = {};

      if (Array.isArray(area) && area.length > 0) {
        const [areaPId, areaId, areaId3] = area;

        if (areaPId) {
          area1 = this.rawAreaList.find((item) => item.id === areaPId);
        }
        if (areaId) {
          area2 = this.rawAreaList.find((item) => item.id === areaId);
        }
        if (areaId3) {
          area3 = this.rawAreaList.find((item) => item.id === areaId3);
        }

        params = {
          ...params,
          areaId: area1?.id,
          areaName: area1?.areaName,
          subAreaId: area2?.id,
          subAreaName: area2?.areaName,
          l3AreaId: area3?.id,
          l3AreaName: area3?.areaName,
          area: undefined,
        };
      }

      this.saveLoading = true;

      edit(params)
        .then(() => {
          this.$close(this.$route.path);
        })
        .finally(() => {
          this.saveLoading = false;
        });
    },

    moneyFilter(money) {
      let startIndex = 0;
      if (parseFloat(money) < 0) {
        startIndex = 1;
      }

      let str = String(money)
        .substring(startIndex)
        .split("")
        .reverse()
        .join("");

      let val = str
        .split("")
        .map((item, index) => {
          if (index + 1 === str.length) {
            return item;
          }
          if ((index + 1) % 3 === 0) {
            return item + ",";
          } else {
            return item;
          }
        })
        .join("")
        .split(",")
        .map((item) => {
          return item.split("").reverse().join("");
        })
        .reverse()
        .join(",");

      if (startIndex === 1) {
        return "-" + val;
      } else {
        return val;
      }
    },

    dateFormatter(date) {
      const dateStr = String(date);
      if (dateStr.length === 6) {
        return dateStr.substring(0, 4) + "-" + dateStr.substring(4);
      } else {
        return date;
      }
    },

    // 合同状态：金慧拉取的数据，合同状态默认为‘已签订’，但是有可能为‘签订中’，所以生产负责人或者院长（新需求，详情见 teambition）修改合同的时候，如果状态为‘已签订’或‘签订中’，只能在这2个状态里面改
    statusDisabled(item) {
      if (this.isProduction || this.isPresident) {
        if (
          this.detail.contractSignStatus === "signed" ||
          this.detail.contractSignStatus === "signing"
        ) {
          if (item.value === "signed" || item.value === "signing") {
            return false;
            // 当前选项是两者之一，不禁用
          } else {
            return true;
          }
        } else {
          return false;
          // 合同状态非那二者，无限制
        }
      } else {
        return false;
        // 非生产管理员，无限制
      }
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  padding: 24px;
}

.center {
  margin-top: 80px;
}
</style>
